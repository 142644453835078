import { createRouter, createWebHistory } from '@ionic/vue-router';
import Tabs from '../views/Tabs.vue';

const routes = [
  {
    path: '/',
    redirect: '/tabs/vessels',
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('@/views/Welcome.vue'),
    meta: {
      title: 'Ankeri | Welcome',
      requiresVisitor: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: 'Ankeri | Login',
      requiresVisitor: true,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/Signup.vue'),
    meta: {
      title: 'Ankeri | Signup',
      requiresVisitor: true,
    },
  },
  {
    path: '/signup-success',
    name: 'signup-success',
    component: () => import('@/views/SignupSuccess.vue'),
    meta: {
      title: 'Ankeri | Signup Success',
      requiresVisitor: true,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/Logout.vue'),
    meta: {
      title: 'Ankeri | Logout',
      requiresAuth: true,
    },
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/vessels',
      },
      {
        path: 'feed',
        component: () => import('@/views/Feed.vue'),
        name: 'feed',
        meta: {
          title: 'Ankeri | Feed',
          requiresAuth: true,
        },
      },
      {
        path: 'profile',
        component: () => import('@/views/Profile.vue'),
        meta: {
          title: 'Ankeri | Profile',
          requiresAuth: true,
        },
      },
      {
        path: 'posts',
        component: () => import('@/views/PostsList.vue'),
        meta: {
          title: 'Ankeri | Posts',
          requiresAuth: true,
        },
      },
      {
        path: 'vessels',
        name: 'vessels',
        component: () => import('@/views/Vessels.vue'),
        meta: {
          title: 'Ankeri | Vessels',
          requiresAuth: true,
        },
      },
      {
        path: 'vessels/:imoNr',
        name: 'vessel',
        component: () => import('@/views/Vessel.vue'),
        meta: {
          title: 'Ankeri | Vessel',
          requiresAuth: true,
        },
      },
      {
        path: 'vessels/:imoNr/modes/:modeStart',
        name: 'modeDetails',
        component: () => import('@/views/ModeDetails.vue'),
        meta: {
          title: 'Ankeri | ModeDetails',
          requiresAuth: true,
        },
        reload: true,
        props: true,
      },
      {
        path: 'post/:uuid',
        name: 'post',
        component: () => import('@/views/Post.vue'),
        meta: {
          title: 'Ankeri | Post',
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
