<template>
  <ion-menu side="end" content-id="main-content" :swipe-gesture="false">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-menu-toggle :auto-hide="false">
            <ion-icon :icon="closeOutline" />
          </ion-menu-toggle>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item @click="selectItem('feed')">
          <ion-icon
            class="menu-icon"
            :icon="apertureOutline"
            :color="isActiveRoute('/tabs/feed') ? 'primary' : 'medium'"
          />
          <ion-text>Activity</ion-text>
        </ion-item>
        <ion-item @click="selectItem('vessels')">
          <ion-icon
            class="menu-icon"
            :icon="boatOutline"
            :color="isActiveRoute('/tabs/vessels') ? 'primary' : 'medium'"
          />
          <ion-text>Vessels</ion-text>
        </ion-item>
        <ion-item v-if="hasFeature('news')" @click="selectItem('posts')">
          <ion-icon
            class="menu-icon"
            :icon="newspaperOutline"
            :color="isActiveRoute('/tabs/posts') ? 'primary' : 'medium'"
          />
          <ion-text>News</ion-text>
          <ion-badge v-if="hasUnread()" color="danger">{{ unreadPosts() }}</ion-badge>
        </ion-item>
        <ion-item @click="selectItem('profile')" lines="none">
          <ion-icon
            class="menu-icon"
            :icon="personCircleOutline"
            :color="isActiveRoute('/tabs/profile') ? 'primary' : 'medium'"
          />
          <ion-text class="item-label">Profile</ion-text>
        </ion-item>
        <ion-item
          v-if="!isActiveRoute('/tabs/profile')"
          @click="toggleDarkMode()"
          class="sub-item"
          lines="none"
        >
          <ion-text>{{ darkMode ? 'Light mode' : 'Dark mode' }}</ion-text>
        </ion-item>
        <ion-item
          v-if="!isActiveRoute('/tabs/profile')"
          @click="selectItem('logout')"
          class="sub-item"
          lines="none"
        >
          <ion-text>Logout</ion-text>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>
import {
  IonMenu,
  IonMenuToggle,
  IonHeader,
  IonToolbar,
  IonContent,
  IonList,
  IonItem,
  IonIcon,
  IonText,
  IonButtons,
  IonBadge,
  menuController,
} from '@ionic/vue';
import {
  personCircleOutline,
  boatOutline,
  apertureOutline,
  closeOutline,
  newspaperOutline,
} from 'ionicons/icons';
import LocalStorageService from '@/services/localStorageService';

export default {
  name: 'SideMenu',
  components: {
    IonMenu,
    IonMenuToggle,
    IonHeader,
    IonToolbar,
    IonContent,
    IonList,
    IonItem,
    IonIcon,
    IonText,
    IonButtons,
    IonBadge,
  },
  setup() {
    return {
      personCircleOutline,
      apertureOutline,
      boatOutline,
      closeOutline,
      newspaperOutline,
    };
  },
  data: () => ({
    darkMode: LocalStorageService.getIsDarkMode(),
  }),
  methods: {
    closeMenu() {
      menuController.close();
    },
    isActiveRoute(pathName) {
      return this.$route.path === pathName;
    },
    selectItem(item) {
      this.closeMenu();
      this.$router.push(item === 'logout' ? '/logout' : `/tabs/${item}`);
    },
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
      document.body.classList.toggle('dark', this.darkMode);
      LocalStorageService.setIsDarkMode(this.darkMode);
      this.closeMenu();
    },
    hasFeature(feature) {
      return (
        this.$store.getters.getAwareConfig?.features?.find((f) => f.feature === feature) !==
        undefined
      );
    },
    hasUnread() {
      return this.$store.getters.postData?.filter((p) => !p.read).length > 0;
    },
    unreadPosts() {
      if (this.hasUnread()) {
        const numUnread = this.$store.getters.postData?.filter((p) => !p.read).length;
        return numUnread > 9 ? `9+` : `${numUnread}`;
      }
      return '';
    },
    fetchPosts() {
      return this.$store
        .dispatch('fetchPostsData')
        .then(() => {})
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
ion-item {
  cursor: pointer;
}

ion-badge {
  margin-left: 3px;
}

ion-toolbar {
  height: 44px;
}
div.main-item {
  display: flex;
}
ion-buttons {
  height: 38px;
  margin-right: 10px;
  margin-top: 4px;
}
ion-buttons.md {
  align-items: flex-start;
}
ion-menu-toggle {
  height: 32px;
}
ion-icon {
  font-size: 26px;
}
ion-icon.menu-icon {
  margin-inline-end: 20px;
}
a {
  text-decoration: none;
}
ion-text {
  color: var(--ion-text-color);
}
.sub-item {
  margin-left: 46px;
  height: 32px;
  align-items: flex-start;
}
</style>
