import Vuex from 'vuex';
import authentication from './modules/authentication';
import states from './modules/states';
import posts from './modules/posts';

// Create store
export default new Vuex.Store({
  modules: {
    authentication,
    states,
    posts,
  },
});
