import AwareConfig from './awareConfig';
import HapagLloydConfig from './hapagLloydConfig';
import LanghShipConfig from './langhShipConfig';
import demoConfig from '@/demo/demoConfig';
import EimskipConfig from './eimskipConfig';
import MACConfig from './macConfig';

const getCompanyConfig = (companyId) => {
  switch (companyId) {
    case 'cf1c03f0-3dd1-419a-b4d4-1483ad6f4c66':
      if (process.env.VUE_APP_IS_DEMO === 'true') {
        return new demoConfig();
      }
      return new HapagLloydConfig();
    case 'd4f3c799-66ae-493f-aefb-457f02281919':
      return new LanghShipConfig();
    case '3f481bd5-ecfe-4533-8e9a-ef5aa0b0c10e':
      return new EimskipConfig();
    case '2dc3026b-2ee9-49bf-9d58-1e1c5d74c63a':
      return new MACConfig();
    case '610a4ffd-be27-4f48-ab1f-28a2f05cbbaf':
      return new MACConfig();
    case '1f2d7515-81ae-4da7-955d-9fa2389bcf12':
      return new MACConfig();
    default:
      return new AwareConfig();
  }
};

export default getCompanyConfig;
