import { getPlatforms, toastController } from '@ionic/vue';
import { globe } from 'ionicons/icons';

export default {
  data: () => ({
    installPromptDelay: 5000,
  }),
  methods: {
    showInstallPrompt() {
      const platform = getPlatforms();
      if (
        !platform.includes('pwa') &&
        platform.includes('mobile') &&
        platform.includes('mobileweb') &&
        (platform.includes('ios') || platform.includes('android'))
      ) {
        setTimeout(() => {
          this.installPrompt();
        }, this.installPromptDelay);
      }
    },
    async installPrompt() {
      const platform = getPlatforms();
      const prefixMessage = 'Access Aware just like a regular app';
      let platFormMessage = '';
      if (platform.includes('ios')) {
        platFormMessage = `${prefixMessage} by opening your Safari menu and tap "Add to Home Screen"`;
      } else if (platform.includes('android')) {
        platFormMessage = `${prefixMessage} by opening your browser menu and selecting "Add to Home Screen"`;
      }

      const toast = await toastController.create({
        color: 'secondary',
        header: 'Add Aware to your home screen',
        icon: globe,
        message: platFormMessage,
        animated: true,
        buttons: [
          {
            text: 'Not now',
            role: 'cancel',
            handler: () => {
              this.handlerMessage = 'Dismiss clicked';
            },
          },
        ],
      });

      await toast.present();
      const { role } = await toast.onDidDismiss();
    },
  },
};
