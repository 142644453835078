import AwareConfig from './awareConfig';

export default class EimskipConfig extends AwareConfig {
  constructor() {
    super();
    this.hasSummary = true;
    this.features = [
      {
        feature: 'weather',
        weatherProvider: 'windy',
      },
    ];
    this.imos = [
      '9290177',
      '9333383',
      '9371426',
      '9375111',
      '9375252',
      '9389306',
      '9433456',
      '9641314',
      '9822841',
      '9822853',
    ];
    this.modeDataFields = [
      {
        mode: 'underway',
        fields: ['duration', 'distance_over_ground', 'speed_over_ground_avg'],
        defaultField: 'speed_over_ground_avg',
        rankFields: [
          {
            field: 'speed_over_ground_avg',
            textSubfix: 'in slowest sea passage speed',
          },
          {
            field: 'duration',
            textSubfix: 'in longest sea passage duration',
          },
        ],
        insightFields: [
          {
            field: 'speed_over_ground_avg',
            textSubfix: 'is the average speed on this sea passage',
          },
          {
            field: 'duration',
            textSubfix: 'is the average duration on this sea passage',
          },
        ],
        modeDetailsTotalFields: ['duration', 'distance_over_ground', 'speed_over_ground_avg'],
        modeDetailsPerformanceFields: [],
        modeDetailsLastMetricsFields: ['speed_over_ground_avg', 'duration'],
        modeDetailsSustainabilityFields: [],
      },
      {
        mode: 'port',
        fields: ['duration'],
        defaultField: 'duration',
        rankFields: [
          {
            field: 'duration',
            textSubfix: 'in shortest port stay',
          },
        ],
        insightFields: [
          {
            field: 'duration',
            textSubfix: 'is the average port stay duration',
          },
        ],
        modeDetailsTotalFields: ['duration'],
        modeDetailsPerformanceFields: [],
        modeDetailsLastMetricsFields: ['duration'],
      },
      {
        mode: 'anchored',
        fields: ['duration'],
        rankFields: [],
        insightFields: [
          {
            field: 'duration',
            textSubfix: 'is average in this port',
          },
        ],
        modeDetailsTotalFields: ['duration'],
        modeDetailsPerformanceFields: [],
        modeDetailsLastMetricsFields: [],
      },
    ];
  }
}
