<template>
  <ion-app>
    <SideMenu />
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
//import { FCM } from '@capacitor-community/fcm';
import { Capacitor } from '@capacitor/core';
//const { PushNotifications } = Plugins;
import { PushNotifications } from '@capacitor/push-notifications';
import LocalStorageService from '@/services/localStorageService';
import SideMenu from '@/components/SideMenu';
import installMixin from '@/mixins/install';

export default defineComponent({
  name: 'App',
  components: {
    SideMenu,
    IonApp,
    IonRouterOutlet,
  },
  mixins: [installMixin],
  setup() {
    document.body.classList.toggle('dark', LocalStorageService.getIsDarkMode());
  },
  mounted() {
    if (Capacitor.isPluginAvailable('PushNotifications')) {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token) => {
        console.log('Push registration success, token: ' + token.value);
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error) => {
        alert('Error on registration: ' + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived', (notification) => {
        alert('Push received: ' + JSON.stringify(notification));
      });

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        alert('Push action performed: ' + JSON.stringify(notification));
      });
    }
    this.showInstallPrompt();
  },
});
</script>

<style>
ion-content {
  background-color: var(--ion-background-color);
}
ion-content::-webkit-scrollbar {
  display: none;
}
ion-popover {
  --width: 97%;
  --max-width: 580px;
}
.select-interface-option {
  --background: var(--ion-off-background-color);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
