class LocalStorageService {
  getAccessToken() {
    return localStorage.getItem('access_token');
  }
  setAccessToken(token) {
    localStorage.setItem('access_token', token);
  }
  deleteAccessToken() {
    localStorage.removeItem('access_token');
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }
  setRefreshToken(token) {
    localStorage.setItem('refresh_token', token);
  }
  deleteRefreshToken() {
    localStorage.removeItem('refresh_token');
  }

  getHasLoggedIn() {
    return JSON.parse(localStorage.getItem('has_logged_in') || 'false');
  }
  setHasLoggedIn(hasLoggedIn) {
    localStorage.setItem('has_logged_in', JSON.stringify(hasLoggedIn));
  }

  getUserEmail() {
    return localStorage.getItem('user_email');
  }
  setUserEmail(email) {
    localStorage.setItem('user_email', email);
  }
  deleteUserEmail() {
    localStorage.removeItem('user_email');
  }

  getDchCompanyId() {
    return localStorage.getItem('dch_company_id');
  }
  setDchCompanyId(dchCompanyId) {
    localStorage.setItem('dch_company_id', dchCompanyId);
  }
  deleteDchCompanyId() {
    localStorage.removeItem('dch_company_id');
  }

  getIsDarkMode() {
    const isDarkMode = JSON.parse(localStorage.getItem('is_dark_mode'));

    // We only check the users color scheme preference if the local storage variable is null,
    // in which case the user hasn't defined his in app preference.
    return (
      isDarkMode ||
      (isDarkMode === null && window.matchMedia('(prefers-color-scheme: dark)').matches)
    );
  }
  setIsDarkMode(isDark) {
    localStorage.setItem('is_dark_mode', JSON.stringify(isDark));
  }

  getSelectedVessels() {
    return JSON.parse(localStorage.getItem('user_selected_vessels') || '{}');
  }
  getUserSelectedVessels(user) {
    const selectedVessels = JSON.parse(localStorage.getItem('user_selected_vessels') || '{}');
    return selectedVessels[user] || [];
  }
  updateSelectedVessels(user, vessels) {
    let selectedVessels = this.getSelectedVessels();
    selectedVessels[user] = vessels;
    localStorage.setItem('user_selected_vessels', JSON.stringify(selectedVessels));
  }

  getNewestSeenMode() {
    return localStorage.getItem('newest_seen_mode');
  }
  setNewestSeenMode(modeUuid) {
    return localStorage.setItem('newest_seen_mode', modeUuid);
  }
}

export default new LocalStorageService();
