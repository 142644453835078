import axiosInstance from './platformAPIv1';
import LocalStorageService from './localStorageService';

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.url !== 'login') {
        config.headers['authorization'] = LocalStorageService.getAccessToken();
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== 'login' && err.response) {
        const errorMessage = err.response.data.message;
        if (
          err.response.status === 401 &&
          !originalConfig._retry &&
          (errorMessage === 'Token is invalid' || errorMessage === 'Token has expired')
        ) {
          originalConfig._retry = true;
          try {
            const res = await axiosInstance.post(`login/refresh`, {
              refreshToken: LocalStorageService.getRefreshToken(),
            });

            const { token, refreshToken } = res.data;

            store.dispatch('refreshLogin', { token, refreshToken });

            return axiosInstance(originalConfig);
          } catch (_error) {
            store.dispatch('logout');
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};

export default setup;
