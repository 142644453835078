import platformAPIv1 from '@/services/platformAPIv1';
import getCompanyConfig from '@/config/getCompanyConfig';
import LocalStorageService from '@/services/localStorageService';

const state = {
  token: LocalStorageService.getAccessToken() || null,
  refreshToken: LocalStorageService.getRefreshToken() || null,
  userEmail: LocalStorageService.getUserEmail() || '',
  dchCompanyId: LocalStorageService.getDchCompanyId() || null,
  hasLoggedIn: LocalStorageService.getHasLoggedIn() || null,
  awareConfig: {},
};

const getters = {
  isLoggedIn(state) {
    return !!state.token;
  },
  hasLoggedIn(state) {
    return state.hasLoggedIn;
  },
  getUserEmail(state) {
    return state.userEmail;
  },
  getCompanyId(state) {
    return state.dchCompanyId;
  },
  getAwareConfig(state) {
    return state.awareConfig;
  },
};

const actions = {
  logout(context) {
    if (context.getters.isLoggedIn) {
      LocalStorageService.deleteAccessToken();
      LocalStorageService.deleteRefreshToken();
      LocalStorageService.deleteUserEmail();
      LocalStorageService.deleteDchCompanyId();
      context.commit('logout');
    }
  },
  refreshLogin(context, { token, refreshToken }) {
    LocalStorageService.setAccessToken(token);
    LocalStorageService.setRefreshToken(refreshToken);

    context.commit('refreshLogin', {
      token,
      refreshToken,
    });
  },
  login(context, credentials) {
    return new Promise((resolve, reject) => {
      const { email } = credentials;
      platformAPIv1
        .post('login', {
          email: credentials.email,
          password: credentials.password,
        })
        .then((response) => {
          if (!response.data.aware) {
            reject('Aware not enabled');
          }
          const { token, dchCompanyId, aware, refreshToken } = response.data;

          LocalStorageService.setAccessToken(token);
          LocalStorageService.setRefreshToken(refreshToken);
          LocalStorageService.setUserEmail(email);
          LocalStorageService.setDchCompanyId(dchCompanyId);
          LocalStorageService.setHasLoggedIn(true);

          context.commit('login', {
            token,
            refreshToken,
            email,
            dchCompanyId,
            aware,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  config(context) {
    const config = getCompanyConfig(context.getters.getCompanyId);
    context.commit('config', config);
  },
  signup() {
    return new Promise((resolve) => {
      setTimeout(() => {
        console.log('Signing up new user');
        resolve('response');
      }, 1000);
    });
  },
};

const mutations = {
  login(state, { token, email, dchCompanyId, aware, refreshToken }) {
    state.token = token;
    state.refreshToken = refreshToken;
    state.userEmail = email;
    state.dchCompanyId = dchCompanyId;
    state.aware = aware;
  },
  refreshLogin(state, { token, refreshToken }) {
    state.token = token;
    state.refreshToken = refreshToken;
  },
  config(state, config) {
    state.awareConfig = config;
  },
  logout(state) {
    state.token = null;
    state.userEmail = null;
    state.dchCompanyId = null;
    state.aware = null;
    state.awareConfig = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
