import AwareConfig from './awareConfig';

export default class MACConfig extends AwareConfig {
  constructor() {
    super();
    this.hasSummary = true;
    this.features = [
      {
        feature: 'weather',
        weatherProvider: 'windy',
      },
    ];
    this.modeDataFields = [
      {
        mode: 'underway',
        fields: ['duration', 'speed_over_ground_avg', 'consumption_per_day'],
        defaultField: 'cii_attained',
        rankFields: [
          {
            field: 'speed_over_ground_avg',
            textSubfix: 'in slowest sea passage speed',
          },
          {
            field: 'duration',
            textSubfix: 'in longest sea passage duration',
          },
          {
            field: 'consumption_per_day',
            textSubfix: 'in lowest consumption per day',
          },
          {
            field: 'total_consumption',
            textSubfix: 'in lowest total consumption',
          },
          {
            field: 'cii_attained',
            textSubfix: 'in lowest CII attained',
          },
        ],
        insightFields: [
          {
            field: 'speed_over_ground_avg',
            textSubfix: 'is the average speed on this sea passage',
          },
          {
            field: 'duration',
            textSubfix: 'is the average duration on this sea passage',
          },
        ],
        modeDetailsTotalFields: ['duration', 'distance_over_ground', 'speed_over_ground_avg'],
        modeDetailsPerformanceFields: [
          'total_consumption',
          'consumption_per_day',
          'consumption_per_nm',
        ],
        modeDetailsLastMetricsFields: [
          'speed_over_ground_avg',
          'consumption_per_day',
          'total_consumption',
        ],
        modeDetailsSustainabilityFields: ['cii_attained', 'cii_rating'],
      },
      {
        mode: 'port',
        fields: ['duration'],
        defaultField: 'duration',
        rankFields: [
          {
            field: 'duration',
            textSubfix: 'in shortest port stay',
          },
        ],
        insightFields: [
          {
            field: 'duration',
            textSubfix: 'is the average port stay duration',
          },
        ],
        modeDetailsTotalFields: ['duration'],
        modeDetailsPerformanceFields: ['total_consumption', 'consumption_per_day'],
        modeDetailsLastMetricsFields: ['duration', 'consumption_per_day', 'total_consumption'],
        modeDetailsSustainabilityFields: ['cii_attained', 'cii_rating'],
      },
      {
        mode: 'anchored',
        fields: ['duration'],
        rankFields: [],
        insightFields: [
          {
            field: 'duration',
            textSubfix: 'is average in this port',
          },
        ],
        modeDetailsTotalFields: ['duration'],
        modeDetailsPerformanceFields: [],
        modeDetailsLastMetricsFields: [],
      },
    ];
  }
}
