import awareAPI from '@/services/awareAPI';
import platformAPIv1 from '@/services/platformAPIv1';
import LocalStorageService from '@/services/localStorageService';
import validateCIIParams from '@/utils/validations';
import getCompanyConfig from '@/config/getCompanyConfig';

const dayjs = require('dayjs');

const state = {
  modes: [],
  modesCursor: '',
  vesselModes: [],
  vesselSummary: {},
  vesselModesCursor: '',
  currentVessel: '',
  modeDetails: {},
  modeFields: [],
  shipsData: [],
  vesselsData: [],
  shipProperties: [],
  timeseries: {},
  path: {},
  newestSeenMode: LocalStorageService.getNewestSeenMode() || '',
  vesselsActiveView: 'list',
  map: {},
  filteredVessels: LocalStorageService.getUserSelectedVessels(LocalStorageService.getUserEmail()),
  feedKey: 0,
};

const getters = {
  modes: (state) => {
    return state.modes;
  },
  modesCursor: (state) => {
    return state.modesCursor;
  },
  vesselModes: (state) => {
    return state.vesselModes;
  },
  vesselSummary: (state) => {
    return state.vesselSummary;
  },
  vesselModesCursor: (state) => {
    return state.vesselModesCursor;
  },
  currentVessel: (state) => {
    return state.currentVessel;
  },
  modeDetails: (state) => {
    return state.modeDetails;
  },
  modeFields: (state) => {
    return state.modeFields;
  },
  shipsData: (state) => {
    return state.shipsData;
  },
  vesselsData: (state) => {
    return state.vesselsData;
  },
  ship: (state, imoNr) => {
    return state.shipsData.find((ship) => ship.imoNr === parseInt(imoNr));
  },
  shipProperties: (state) => {
    return state.shipProperties;
  },
  timeseries: (state) => {
    return state.timeseries;
  },
  path: (state) => {
    return state.path;
  },
  newestSeenMode: (state) => {
    return state.newestSeenMode;
  },
  vesselsActiveView: (state) => {
    return state.vesselsActiveView;
  },
  map: (state) => {
    return state.map;
  },
  filteredVessels: (state) => {
    return state.filteredVessels;
  },
  feedKey: (state) => {
    return state.feedKey;
  },
};

const actions = {
  async fetchModesData({ commit, getters, dispatch }, isPulldownRefresh) {
    const config = getCompanyConfig(getters.getCompanyId);
    const allVessels = getters.shipsData;
    const companyVessels = getters.vesselsData.filter((mode) =>
      allVessels.find((ship) => ship.imoNr === parseInt(mode.ship_imo))
    );
    const filteredVessels = getters.filteredVessels;
    const numberOfCompanyVessels = companyVessels.length;
    const numberOfFilteredVessels = filteredVessels.length;
    const calculatedLimit =
      numberOfFilteredVessels > 0
        ? Math.ceil((numberOfCompanyVessels / numberOfFilteredVessels) * config.limit)
        : config.limit;
    const limitParam = `?limit=${calculatedLimit}`;
    let cursorParam = state.modesCursor && !isPulldownRefresh ? `&cursor=${state.modesCursor}` : '';
    let url = `modes${limitParam}${cursorParam}`;
    let instance = awareAPI;

    return new Promise((resolve, reject) => {
      instance
        .get(url)
        .then((response) => {
          let modes = response.data.modes
            .filter((r) => config.imos.includes(r.ship_imo) || config.imos.length == 0)
            .map((r) => (r = { ...r }));

          if (filteredVessels.length > 0) {
            modes = modes.filter((mode) => filteredVessels.includes(mode.ship_imo));
          }

          const cursor = response.data.cursor;

          if (modes.length > 0 && (state.modes.length === 0 || isPulldownRefresh)) {
            commit('setNewestSeenMode', { modeUuid: LocalStorageService.getNewestSeenMode() });
            const modeUuid = modes[0].uuid;
            LocalStorageService.setNewestSeenMode(modeUuid);
          }

          dispatch('fetchInteractions', {
            ownerObjectsIds: modes.map((m) => m.uuid),
            modeDetails: false,
          });

          commit('setModeData', { modes, cursor, isPulldownRefresh });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchVesselModesData({ commit, getters, dispatch }, imoNr) {
    const config = getCompanyConfig(getters.getCompanyId);
    const limitParam = `?limit=${config.limit}`;
    const useCursor = state.vesselModesCursor && state.currentVessel === imoNr;

    let cursorParam = useCursor ? `&cursor=${state.vesselModesCursor}` : '';
    let url = `vessels/${imoNr}/modes${limitParam}${cursorParam}`;
    let instance = awareAPI;

    return new Promise((resolve, reject) => {
      instance
        .get(url)
        .then((response) => {
          const modes = response.data.modes.map((r) => (r = { ...r }));

          dispatch('fetchInteractions', {
            ownerObjectsIds: modes.map((m) => m.uuid),
            modeDetails: false,
          });

          const cursor = response.data.cursor;
          commit('setVesselModeData', {
            modes,
            cursor,
            imoNr,
            shouldAppend: useCursor,
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchVesselSummaryData({ commit, getters }, { imoNr, modeStart, interval }) {
    return new Promise((resolve, reject) => {
      const url = `vessels/${imoNr}/summaries?interval=${interval}`;
      let instance = awareAPI;
      instance
        .get(url)
        .then((response) => {
          commit('setVesselSummaryData', {
            summary: response.data.summaries,
            imoNr,
            modeStart,
            interval,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchModeDetails({ commit, getters }, { imoNr, modeStart }) {
    const config = getCompanyConfig(getters.getCompanyId);
    let url = `vessels/${imoNr}/modes/${modeStart}`;
    let instance = awareAPI;
    return new Promise((resolve, reject) => {
      instance
        .get(url)
        .then((response) => {
          const stateMode = state.modes.find((m) => m.uuid === response.data.mode.uuid);
          const modeData = response.data.mode;
          const mode = {
            ...modeData,
          };

          commit('setModeDetails', mode);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchModeMetrics({ commit, getters }, { imoNr, modeStart, modeEnd, source, dataType }) {
    const config = getCompanyConfig(getters.getCompanyId);
    return new Promise((resolve, reject) => {
      awareAPI
        .get(
          `assets/${imoNr}/modes/${modeStart}/aggregations` +
            `?source_handle=${source}&mode_end=${modeEnd}`
        )
        .then((response) => {
          const metrics = response.data;
          commit('setModeMetrics', { metrics, imoNr, modeStart, dataType });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchFieldsData({ commit }) {
    return new Promise((resolve, reject) => {
      awareAPI
        .get('modes/fields')
        .then((response) => {
          const responseData = response.data.map((r) => (r = { ...r }));
          commit('setModeFields', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchShipsData({ commit }) {
    return new Promise((resolve, reject) => {
      platformAPIv1
        .get('ships')
        .then((response) => {
          const responseData = response.data.ships;
          commit('setShipsData', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchVesselsData({ commit, getters }) {
    const config = getCompanyConfig(getters.getCompanyId);
    let url = `vessels`;
    let instance = awareAPI;
    return new Promise((resolve, reject) => {
      instance
        .get(url)
        .then((response) => {
          let modes = [];
          response.data.modes
            .filter(
              (r) =>
                r.status === 'active' &&
                (config.imos.includes(r.ship_imo) || config.imos.length == 0)
            )
            .forEach((mode) => {
              // If we get duplicate ship IMOs, we pick the one with the latest start date
              const existingMode = modes.find((m) => m.ship_imo === mode.ship_imo);
              if (existingMode) {
                if (dayjs(mode.start_time) > dayjs(existingMode.start_time)) {
                  const index = modes.indexOf(existingMode);
                  if (~index) {
                    // Replace existing mode
                    modes[index] = mode;
                  }
                }
              } else {
                modes.push(mode);
              }
            });

          commit('setVesselsData', modes);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchShipProperties({ commit }, { imoNr }) {
    return new Promise((resolve, reject) => {
      platformAPIv1
        .get(`ships/${imoNr}/properties`)
        .then((response) => {
          const responseData = response.data.properties.map((r) => (r = { ...r }));
          commit('setShipProperties', responseData);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchCII({ commit }, { imoNr, capacity, distance, consumption, endTime, modeStart }) {
    return new Promise((resolve, reject) => {
      if (!validateCIIParams(imoNr, capacity, distance, consumption, endTime)) {
        reject('Invalid params');
        return;
      }
      awareAPI
        .post('models/cii', [
          {
            ship_imo_nr: imoNr,
            ship_type: 'container_ship',
            capacity: parseInt(capacity),
            distance_sailed: Math.round(distance),
            consumption,
            year: parseInt(endTime.substr(0, 4)),
          },
        ])
        .then((response) => {
          if (modeStart) {
            commit('setModeCii', { cii: response.data, imoNr, modeStart });
            commit('setVesselModeCii', { cii: response.data, imoNr, modeStart });
          } else {
            commit('setModeDetailsCii', response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchTimeseries({ commit }, { imoNr, start, end, tags, source }) {
    return new Promise((resolve, reject) => {
      awareAPI
        .get(
          `vessels/${imoNr}/timeseries` +
            `?tags=${tags}&start=${start}&end=${end}&source_handle=${source}`
        )
        .then((response) => {
          if (tags === 'position') {
            commit('setPath', response.data);
            commit('setModePath', { path: response.data, imoNr, modeStart: start });
          } else {
            commit('setTimeseries', response.data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchLastModes({ commit }, { imoNr, sourceType, locode, toLocode, handle }) {
    return new Promise((resolve, reject) => {
      awareAPI
        .get(
          `/vessels/${imoNr}/modes?status=completed&limit=5&source_type=${sourceType}` +
            `&from_locode=${locode}&to_locode=${toLocode}&handle=${handle}`
        )
        .then((response) => {
          const modes = response.data.modes.filter((mode) => Object.keys(mode).length);
          commit('setLastModes', modes);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  changeVesselsView({ commit }, { activeView }) {
    commit('setVesselsView', { activeView });
  },
  setMap({ commit }, { map }) {
    commit('setMap', { map });
  },
  destroyMap({ commit }, { map }) {
    commit('deleteMap', { map });
    // prevent duplicates of the sidemenu
    if (window.W.plugins?.menu) {
      window.W.plugins.menu = null;
    }
  },
  saveFilteredVessels({ commit }, { vessels }) {
    commit('saveFilters', { vessels });
    LocalStorageService.updateSelectedVessels(LocalStorageService.getUserEmail(), vessels);
  },
  incrementFeedKey({ commit }) {
    commit('incFeedKey');
  },
  setVesselSummary({ commit }, { summary, interval }) {
    commit('setVesselSummaryData', { summary, interval });
  },
  async fetchInteractions({ commit }, { ownerObjectsIds, modeDetails }) {
    return new Promise((resolve, reject) => {
      platformAPIv1
        .post('social/interactions', {
          ownerObjectsIds: ownerObjectsIds,
        })
        .then((response) => {
          const interactions = response.data;
          if (modeDetails) {
            commit('setModeDetailsInteractions', { interactions });
          } else {
            commit('setModeInteractions', { interactions });
            commit('setVesselModeInteractions', { interactions });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async createComment(
    { commit },
    { itemId, comment, ownerObjectType, ownerObjectId, creatorEmail, updated, modeDetail }
  ) {
    let commentObject = {
      itemId: itemId,
      text: comment,
      ownerObjectId: ownerObjectId,
      ownerObjectType: ownerObjectType,
    };
    return new Promise((resolve, reject) => {
      platformAPIv1
        .post('social/comment/create', commentObject)
        .then((response) => {
          const cObject = {
            itemId: itemId,
            text: comment,
            ownerObjectId: ownerObjectId,
            ownerObjectType: ownerObjectType,
            comment: commentObject.text,
            creatorEmail: creatorEmail,
            updated: updated,
          };
          if (modeDetail) {
            commit('setModeDetailComment', { cObject });
          }
          commit('setModeComment', { cObject });
          commit('setVesselModeComment', { cObject });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async createReaction(
    { commit },
    { itemId, reaction, ownerObjectType, ownerObjectId, creatorEmail, updated, modeDetail }
  ) {
    let reactionObject = {
      itemId: itemId,
      reaction: reaction,
      ownerObjectId: ownerObjectId,
      ownerObjectType: ownerObjectType,
    };
    return new Promise((resolve, reject) => {
      platformAPIv1
        .post('social/reaction/create', reactionObject)
        .then((response) => {
          const rObject = {
            itemId: itemId,
            ownerObjectId: ownerObjectId,
            ownerObjectType: ownerObjectType,
            reaction: reaction,
            creatorEmail: creatorEmail,
            updated: updated,
          };
          if (modeDetail) {
            commit('setVesselModeDetailReaction', { rObject });
          }
          commit('setModeReaction', { rObject });
          commit('setVesselModeReaction', { rObject });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async removeReaction(
    { commit },
    { itemId, reaction, ownerObjectType, ownerObjectId, creatorEmail, updated, modeDetail }
  ) {
    return new Promise((resolve, reject) => {
      platformAPIv1
        .delete(`social/reaction/${itemId}`)
        .then((response) => {
          const rObject = {
            itemId: itemId,
            ownerObjectId: ownerObjectId,
            ownerObjectType: ownerObjectType,
            reaction: reaction,
            creatorEmail: creatorEmail,
            updated: updated,
          };
          if (modeDetail) {
            commit('setRemoveVesselModeDetailReaction', { rObject });
          }
          commit('setRemoveModeReaction', { rObject });
          commit('setRemoveVesselModeReaction', { rObject });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setModeData: (state, { modes, cursor, isPulldownRefresh }) => {
    state.modes = isPulldownRefresh ? modes : [...state.modes, ...modes];
    state.modesCursor = cursor;
  },
  setVesselModeData: (state, { modes, cursor, imoNr, shouldAppend }) => {
    state.vesselModes = shouldAppend ? [...state.vesselModes, ...modes] : modes;
    state.vesselModesCursor = cursor;
    state.currentVessel = imoNr;
  },
  setVesselSummaryData: (state, { summary, imoNr, modeStart, interval }) => {
    state.vesselSummary[interval] = summary;
    if (!modeStart) {
      state.currentVessel = imoNr;
    }
    const index = state.vesselsData.findIndex(
      (mode) => mode.ship_imo === imoNr && mode.start_time === modeStart
    );
    if (index > -1) {
      if (state.vesselsData[index].summary.length === 0) {
        state.vesselsData[index].summary = {};
      }
      state.vesselsData[index].summary[interval] = summary;
    }
  },
  setModeDetails: (state, mode) => {
    state.modeDetails = mode;
  },
  setModeMetrics: (state, { metrics, imoNr, modeStart, dataType }) => {
    const index = state.modes.findIndex(
      (mode) => mode.ship_imo === imoNr && mode.start_time === modeStart
    );
    if (index > -1) {
      state.modes[index].metrics[dataType] = [...state.modes[index].metrics[dataType], ...metrics];
    }
  },
  setModeFields: (state, modeFields) => {
    state.modeFields = modeFields;
  },
  setShipsData: (state, shipsData) => {
    state.shipsData = shipsData;
  },
  setVesselsData: (state, vesselsData) => {
    state.vesselsData = vesselsData;
  },
  setShipProperties: (state, shipProperties) => {
    state.shipProperties = shipProperties;
  },
  setTimeseries: (state, timeseries) => {
    state.timeseries = timeseries;
  },
  setPath: (state, path) => {
    state.path = path.position;
  },
  setModeDetailsCii: (state, cii) => {
    state.modeDetails.cii = cii;
  },
  setNewestSeenMode: (state, { modeUuid }) => {
    state.newestSeenMode = modeUuid;
  },
  setModePath: (state, { path, imoNr, modeStart }) => {
    const index = state.vesselsData.findIndex(
      (mode) => mode.ship_imo === imoNr && mode.start_time === modeStart
    );
    if (index > -1) {
      state.vesselsData[index].path = path.position;
    }
  },
  setModeCii: (state, { cii, imoNr, modeStart }) => {
    const index = state.modes.findIndex(
      (mode) => mode.ship_imo === imoNr && mode.start_time === modeStart
    );
    if (index > -1) {
      state.modes[index].cii = cii;
    }
  },
  setVesselModeCii: (state, { cii, imoNr, modeStart }) => {
    const index = state.vesselModes.findIndex(
      (mode) => mode.ship_imo === imoNr && mode.start_time === modeStart
    );
    if (index > -1) {
      state.vesselModes[index].cii = cii;
    }
  },
  setLastModes: (state, lastModes) => {
    state.modeDetails.lastModes = lastModes;
  },
  setVesselsView: (state, { activeView }) => {
    state.vesselsActiveView = activeView;
  },
  setMap: (state, { map }) => {
    state.map = map;
  },
  deleteMap: (state, { map }) => {
    if (Object.entries(map).length > 0) {
      map.remove();
    }
    state.map = {};
  },
  saveFilters: (state, { vessels }) => {
    state.filteredVessels = vessels;
  },
  incFeedKey: (state) => {
    state.feedKey += 1;
  },
  setModeInteractions: (state, { interactions }) => {
    state.modes = state.modes.map(
      (m) =>
        (m = {
          ...m,
          comments: interactions.comments.filter((c) => c.ownerObjectId === m.uuid),
          reactions: interactions.reactions.filter((r) => r.ownerObjectId === m.uuid),
        })
    );
  },
  setVesselModeInteractions: (state, { interactions }) => {
    state.vesselModes = state.vesselModes.map(
      (m) =>
        (m = {
          ...m,
          comments: interactions.comments.filter((c) => c.ownerObjectId === m.uuid),
          reactions: interactions.reactions.filter((r) => r.ownerObjectId === m.uuid),
        })
    );
  },
  setModeDetailsInteractions: (state, { interactions }) => {
    state.modeDetails.comments = interactions.comments;
    state.modeDetails.reactions = interactions.reactions;
  },
  setModeComment: (state, { cObject }) => {
    const index = state.modes.findIndex((m) => m.uuid === cObject.ownerObjectId);
    if (index > -1) {
      state.modes[index].comments.push(cObject);
    }
  },
  setVesselModeComment: (state, { cObject }) => {
    const index = state.vesselModes.findIndex((m) => m.uuid === cObject.ownerObjectId);
    if (index > -1) {
      state.vesselModes[index].comments.push(cObject);
    }
  },
  setModeDetailComment: (state, { cObject }) => {
    state.modeDetails.comments.push(cObject);
  },
  setModeReaction: (state, { rObject }) => {
    const index = state.modes.findIndex((m) => m.uuid === rObject.ownerObjectId);
    if (index > -1) {
      state.modes[index].reactions.push(rObject);
    }
  },
  setVesselModeReaction: (state, { rObject }) => {
    const index = state.vesselModes.findIndex((m) => m.uuid === rObject.ownerObjectId);
    if (index > -1) {
      state.vesselModes[index].reactions.push(rObject);
    }
  },
  setVesselModeDetailReaction: (state, { rObject }) => {
    state.modeDetails?.reactions.push(rObject);
  },
  setRemoveModeReaction: (state, { rObject }) => {
    const index = state.modes.findIndex((m) => m.uuid === rObject.ownerObjectId);
    if (index > -1) {
      const itemIndex = state.modes[index].reactions.findIndex((r) => r.itemId === rObject.itemId);
      state.modes[index].reactions.splice(itemIndex, 1);
    }
  },
  setRemoveVesselModeReaction: (state, { rObject }) => {
    const index = state.vesselModes.findIndex((m) => m.uuid === rObject.ownerObjectId);
    if (index > -1) {
      const itemIndex = state.vesselModes[index].reactions.findIndex(
        (r) => r.itemId === rObject.itemId
      );
      state.vesselModes[index].reactions.splice(itemIndex, 1);
    }
  },
  setRemoveVesselModeDetailReaction: (state, { rObject }) => {
    const itemIndex = state.modeDetails?.reactions.findIndex((r) => r.itemId === rObject.itemId);
    if (itemIndex > -1) {
      state.modeDetails.reactions.splice(itemIndex, 1);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
