import axios from 'axios';
import axiosInstance from './awareAPI';
import LocalStorageService from './localStorageService';

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers['authorization'] = LocalStorageService.getAccessToken();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      store.dispatch('config');
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const platformUrl = process.env.VUE_APP_PLATFORM_API_V1_URL;
            const res = await axios.post(`${platformUrl}login/refresh`, {
              refreshToken: LocalStorageService.getRefreshToken(),
            });

            const { token, refreshToken } = res.data;

            store.dispatch('refreshLogin', { token, refreshToken });

            return axiosInstance(originalConfig);
          } catch (_error) {
            store.dispatch('logout');
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};

export default setup;
