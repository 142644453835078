export default class AwareConfig {
  constructor() {
    this.data = 'aware';
    this.hasSocial = true;
    this.hasSummary = true;
    this.showCIIinVesselHeader = false;
    this.hasGlobeMap = true;
    this.imos = [];
    this.features = [];
    this.modeDataFields = [];
    this.demo = false;
    this.limit = 10;
    this.orderOfMetricDataSourcePreference = ['reported', 'autologged', 'ais'];
    this.orderOfTimeSeriesDataSourcePreference = ['autologged', 'ais', 'reported'];
  }
}
