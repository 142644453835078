import axios from 'axios';

import { v4 as uuidv4 } from 'uuid';

const state = {
  postData: [],
};

const getters = {
  postData: (state) => {
    return state.postData;
  },
};

const actions = {
  async fetchPostsData({ commit }) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const responseData = posts.map((r) => (r = { ...r }));
        commit('setPostData', responseData);
        resolve('response');
      }, 1000);
    });
  },
};

const posts = [
  {
    category: 'new builds',
    title: 'Langh Ship and Outokumpu deepen the cooperation by starting a newbuilding project',
    content: `<p>Langh Ship has ordered three multipurpose dry cargo vessels which will serve Outokumpu on the short sea lane between Finland and continental Europe.</p>
    <p>Outokumpu, a global leader in stainless steel and a forerunner in sustainability, has entered a long-term time charter with the Finnish shipping company Langh Ship for three newbuildings delivered by the Wuhu shipyard in China. Delivery for the first vessel is scheduled mid-2023, followed by the other two at close intervals.</p>
    <p>The newbuildings are designed for all-year traffic in the northern Gulf of Bothnia and will have ice class 1A. The vessels are set to operate between Outokumpu’s main port, Tornio, Finland, and its continental hub Terneuzen, the Netherlands. The vessels will transport semi-finished and finished steel products to customers and for further processing. On the back-haul the vessels will be loaded with steel scrap, which is the main raw material of Outokumpu stainless steel. </p>
    <p>The ordered vessels will have a deadweight of 7,800 tons, thus be of ideal size for the requested service frequency. The design has been developed by Langh Ship in collaboration with Outokumpu and the Chinese ship designer Shanghai Merchant Ship Design and Research Institute (SDARI). Langh Ship has three multipurpose cargo ships, specially equipped for shipments of steel, currently charter to Outokumpu. The experiences from many years of cooperation on this trade has been utilized in designing cargo holds and cargo handling solutions for the new vessels.
    </p>
    <blockquote>We highly value the long-term relationship that we have with Outokumpu. The cooperation between our companies has started over three decades ago. We very much look forward to this next step with these newbuildings, which deepens our cooperation even further,</blockquote><p>says Laura Langh-Lagerlöf, Managing Director, Langh Ship.</p>
    
    
    <p>Jyrki Sandelin, Outokumpu Category Manager for transports:</p><blockquote>This transport stretch is the single most important transport lane for Outokumpu Group and that is why we rely on our trusted partner Langh Ship. Our cooperation and the previous experience gathered on this northern route has led to excellent solutions. Both companies have high ambitions and keep pushing each other for constant improvements.</blockquote> 
    <p>The newbuildings will be specially equipped for shipping steel but as multipurpose vessels are fit for many cargo types to serve Outokumpu versatile needs. The main product, stainless steel coils have a secure ride with Langh Ship’s patented pontoon-type coil cradle tween deck. By loading coils both on the bottom of the hold and on the tween deck, the weight is distributed in a way making the ship's motions in heavy seas slower. This makes the transport safer and reduces the risk of cargo damage.</p>    `,
    uuid: '1',
    author: 'helgi@ankeri.is',
    publish_date: '2022-02-14 22:34:00',
    img: 'https://static.wixstatic.com/media/813dcb_2f5c706cef1c44c09544e992f923e18a~mv2.png',
    read: false,
  },
  {
    category: 'new vessels',
    title: 'Langh Ship buys container vessels',
    content: `<p><i>Langh Ship expands its capacity with the purchase of two 889 TEU container vessels from Germany.</i></p>
    <p>Langh Ship Oy Ab has recently integrated container vessels Charlotta and Christina into its fleet. Charlotta was built in 2009 and Christina in 2010 at the Mawei shipyard in China. They are ice strengthened to ice class 1A and are therefore ideal for traffic on the Baltic Sea even in wintertime.

    </p><p>
    Christina and Charlotta are now time chartered to the French shipowner CMA CGM, which operates in ocean freight as well as in feeder and short sea shipping with container vessels. Christina is currently employed on the Gdynia-Tilbury line, while Charlotta is in liner trade on the Mediterranean Sea. Laura Langh-Lagerlöf, Managing Director of Langh Ship, says that the charter to CMA CGM made the purchase of the vessels possible.
    
    </p><p>
    “We are very happy to extend our collaboration with CMA CGM. Before this we already had two container vessels chartered to Containerships, which is owned by CMA CGM. The charter to CMA CGM for both new acquisitions was part of the package which made the investment possible”, she says.
    
    </p><p>
    Laura Langh-Lagerlöf states that the supply of modern, ice-strengthened container vessels in the size class of under 1000 TEU (20-foot equivalent units) is quite small on the second-hand market. After the financial crisis of 2008 the interest for new building of container vessels in this size segment has been significantly lower than before.
    
    </p>
    <blockquote>It feels like a rather good timing for the purchase of these vessels. The second hand prices are on a fairly low level, but at the same time freight rates seem to be on a rise even for vessels of feeder size</blockquote>
    <p>, Laura Langh-Lagerlöf explains.</p>`,
    uuid: '2',
    author: 'helgi@ankeri.is',
    publish_date: '2021-01-12 05:34:00',
    img: 'https://static.wixstatic.com/media/813dcb_739a980b5b564cdc9b16defb37ad6dd4~mv2.jpg',
    read: false,
  },
  {
    category: 'sustainability',
    title: 'Langh Ship focuses on environmental friendliness',
    content: `<p>The Langh companies take environmental matters into account in their day-to-day operations, and efforts to improve the state of the world’s seas are important to all Langh companies. The environmental perspective has been at the core of the Langh companies’ operations for some time now, and the companies are a partner of the John Nurminen Foundation and a supporter of the Foundation’s Clean Baltic Sea projects.</p>
    <p>
    Langh Tech’s closed loop scrubbers were installed in Langh Ship’s five cargo vessels back in 2013 and 2014. In the closed cycle, the water used to clean exhaust gases can be cleaned in a water-processing unit on board the vessel, and the resulting waste is delivered ashore in solid form. In addition to this waste, wastewater, such as latrine wastewater from Langh Ship’s vessels ms Linda and ms Aila, which sail to Helsinki, is voluntarily collected and delivered to the waste reception point at the Vuosaari harbour.

The shipping company actively seeks new ways of taking the environment better into account, and one important action is that, from now on, ms Linda and ms Aila will voluntarily use the closed-loop scrubber during all piloted journeys, even if the open-loop system is permitted in the area. This will help minimise the emissions released to sensitive coastal waters.</p>
    <blockquote>Ms Linda and ms Aila sail on the Baltic Sea and the North Sea, stopping at major northern European harbours. Using the closed-loop scrubber system during piloted journeys helps us do our part to positively influence the state of the seas surrounding major harbours</blockquote>
    <p>,says Langh Ship's Managing Director Laura Langh-Lagerlöf.</p>`,
    uuid: '3',
    author: 'helgi@ankeri.is',
    publish_date: '2019-09-30 11:11:00',
    img: 'https://static.wixstatic.com/media/813dcb_4a115ccf25b74dd2ae95c2abfe885e91~mv2.jpg',
    read: false,
  },
];

const mutations = {
  setPostData: (state, postData) => {
    state.postData = postData;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
